import React, { useState, useMemo } from "react";
import { Switch, Route } from "react-router-dom";
import WeatherWidget from "./components/WeatherWidget";
import InputLocation from "./components/InputLocation";
import LocationDemo from "./components/LocationDemo";
import { LocationContext } from "./contexts/LocationContext";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "./styles.css";

function App(props) {
  const INITIAL_STATE = { city: "", country: "" };
  const getSavedLocation = () => {
    const isReturningUser = "location" in localStorage;
    const savedLocation = JSON.parse(localStorage.getItem("location"));

    if (isReturningUser) {
      return savedLocation;
    } else {
      return INITIAL_STATE;
    }
  };
  const [location, setLocation] = useState(getSavedLocation());
  const providerValue = useMemo(
    () => ({ location, setLocation, INITIAL_STATE }),
    [location, setLocation, INITIAL_STATE]
  );

  return (
    <LocationContext.Provider value={providerValue}>
      <div className="App">
        <ReactNotification />
        <Switch>
          <Route exact path="/" component={WeatherWidget} />
        </Switch>
      </div>
    </LocationContext.Provider>
  );
}

export default App;
