import React, { useContext } from "react";
import useFetch from "./../hooks/useFetch";
import ForecastCards from "./ForecastCards";
import { LocationContext } from "./../contexts/LocationContext";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import styled from "styled-components";
import { MdSort, MdLocationOn } from "react-icons/md";
import { IconContext } from "react-icons";
import WeatherToday from "./WeatherToday";

const LoaderWrapper = styled.div`
  margin-top: 5rem;
`;

const NavWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonContainer = styled.div`
  margin-top: 2rem;

  button {
    border: 1px solid #dbe7fc;
    background: transparent;
    padding: 15px 25px;
    border-radius: 50px;
    color: #dbe7fc;
    font-size: 1rem;
    font-weight: 600;
  }
`;

const mesi = {
  "gen": "Gennaio",
  "feb": "Febbraio",
  "mar": "Marzo",
  "apr": "Aprile",
  "mag": "Maggio",
  "giu": "Giugno",
  "lug": "Luglio",
  "ago": "Agosto",
  "set": "Settembre",
  "ott": "Ottobre",
  "nov": "Novembre",
  "dic": "Dicembre",
}

const WeatherWidget = () => {
  const { data, loading } = useFetch(
    `https://www.prolocofiamignano.it/ftpmeteo/meteo.php`
  );

  const dateArray = data?.dataora.split(" ")[0].split("-");

  return (
    <>
      {loading ? (
        <LoaderWrapper>
          <Loader type="Puff" color="#dbe7fc" height={100} width={100} />
        </LoaderWrapper>
      ) : (
        <>
          <NavWrapper>
            <IconContext.Provider
              value={{
                color: "#dbe7fc",
                className: "global-class-name",
                size: "1.5rem",
              }}
            >
              <MdSort />
              <span>{dateArray ? `${dateArray[0].replace(/^0/, '')} ${mesi[dateArray[1]] || dateArray[1]}` : ""}</span>
              <span>Fiamignano</span>
              {/* <MdLocationOn /> */}
            </IconContext.Provider>
          </NavWrapper>

          <WeatherToday data={data} />
        </>
      )}
    </>
  );
};

export default WeatherWidget;
