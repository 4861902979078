import React from "react";
import styled from "styled-components";
import { format, parse } from "date-fns";
import { it } from "date-fns/locale";

const Wrapper = styled.div`
  margin: 2rem 0;

  .label {
    color: #b8cdfe;
    font-size: 0.9rem;
    margin-right: 0.5em;
  }

  .value {
    color: #ffffff;
  }

  .ave-tempt__value {
    display: block;
    font-size: 4rem;
    font-weight: 700;
    background: -webkit-linear-gradient(#eef4fe, #c0dbff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media screen and (max-width: 600px) {
      font-size: 3rem;
    }
  }

  .ave-tempt__label {
    display: block;
    color: #b9ceff;
  }

  .today-section {
    &__date {
      color: #b9ceff;
    }

    &__temperature,
    &__wind {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 2rem 0;
    }
  }
`;

const WeatherToday = ({ data }) => {
  return (
    <Wrapper>
      {/* <div className="today-section__weather-icon">
        <img src={`http://openweathermap.org/img/wn/02d@2x.png`} alt="" />
      </div> */}

      <div className="today-section__temperature">
        {/* <div className="min-tempt">
          <img src={`http://openweathermap.org/img/wn/02d@2x.png`} alt="" />
        </div> */}

        <div className="ave-tempt">
          <span className="ave-tempt__value">{data.temp}</span>
          <span className="ave-tempt__label">Temperatura attuale</span>
        </div>

        <div className="max-tempt">
          <div className="rowTemp">
            <span className="label">Min ({data.tempminhour}):</span>
            <span className="value">{data.tempmin}</span>
          </div>
          <div className="rowTemp">
            <span className="label">Max ({data.tempmaxhour}):</span>
            <span className="value">{data.tempmax}</span>
          </div>
        </div>
      </div>

      <div className="today-section__wind">
        <div className="wind__info">
          <span className="label">Vento:</span>
          <span className="value">
            {data.ventodir[0]} {data.vento}
          </span>
        </div>
        <div className="wind__info">
          <span className="label">Raffiche:</span>
          <span className="value">{data.rafficamaxday}</span>
        </div>
      </div>

      <div className="today-section__rain">
        <div className="rain__info">
          <span className="label">Pioggia giorno:</span>
          <span className="value">{data.pioggiaday}</span>
        </div>
        <div className="rain__info">
          <span className="label">Mese:</span>
          <span className="value">{data.pioggiamese}</span>
        </div>
        <div className="rain__info">
          <span className="label">Anno:</span>
          <span className="value">{data.pioggiayear}</span>
        </div>
      </div>
    </Wrapper>
  );
};

export default WeatherToday;
